import React, { useState } from "react";
import { create } from "ipfs-http-client";
import { Buffer } from "buffer";
import "./App.css";

// IPFS client configuration
const projectId = "2DBM8M1VMImQ5vl4IQxYQzI8uKg";
const projectSecret = "f34bbedac4f01e7e79a9c99d04ba08cf";
const auth =
  "Basic " + Buffer.from(projectId + ":" + projectSecret).toString("base64");

const client = create({
  host: "ipfs.infura.io",
  port: 5001,
  protocol: "https",
  apiPath: "/api/v0",
  headers: { authorization: auth },
});

function App() {
  const [fileUrl, setFileUrl] = useState("");
  const [fileMetadata, setFileMetadata] = useState(null);
  const [darkMode, setDarkMode] = useState(false);

  const formatFileSize = (bytes) => {
    if (bytes < 1024) return `${bytes} bytes`;
    if (bytes < 1024 * 1024) return `${(bytes / 1024).toFixed(2)} KB`;
    return `${(bytes / (1024 * 1024)).toFixed(2)} MB`;
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    // Extract file metadata
    const metadata = {
      name: file.name,
      type: file.type || "Unknown file type",
      size: formatFileSize(file.size),
      lastModified: new Date(file.lastModified).toLocaleString(),
    };
    setFileMetadata(metadata);

    try {
      const added = await client.add(file);

      // Try different URL construction methods
      const urlVariants = [
        `https://freeweb3.infura-ipfs.io/ipfs/${added.path}`,
        `https://freeweb3.infura-ipfs.io/ipfs/${
          added.path
        }${file.name.substring(file.name.lastIndexOf("."))}`,
        `https://ipfs.infura.io/ipfs/${added.path}`,
        `https://ipfs.infura.io/ipfs/${added.path}${file.name.substring(
          file.name.lastIndexOf(".")
        )}`,
      ];

      console.log("URL Variants:", urlVariants);

      // Try fetching each URL to find a working one
      for (const url of urlVariants) {
        try {
          const response = await fetch(url, {
            method: "GET",
            headers: {
              Accept: "image/*",
              "Access-Control-Allow-Origin": "*",
            },
          });

          if (response.ok) {
            console.log("Successfully found working URL:", url);
            setFileUrl(url);
            return;
          }
        } catch (fetchError) {
          console.error("Error fetching URL:", url, fetchError);
        }
      }

      throw new Error("No working URL found");
    } catch (error) {
      console.error("Error uploading file: ", error);
    }
  };

  const renderFilePreview = (fileUrl) => {
    if (!fileUrl) return null;

    return (
      <div>
        <img
          src={fileUrl}
          alt="Uploaded File"
          className="uploaded-file"
          crossOrigin="anonymous"
          onError={(e) => {
            console.error("Image load error", e);
            console.log("Failed URL:", fileUrl);
          }}
          style={{
            maxWidth: "100%",
            maxHeight: "400px",
            border: "2px solid red", // Added to ensure visibility
          }}
        />
        {fileMetadata && (
          <div className="file-metadata">
            <h3>File Metadata</h3>
            <p>
              <strong>Name:</strong> {fileMetadata.name}
            </p>
            <p>
              <strong>Type:</strong> {fileMetadata.type}
            </p>
            <p>
              <strong>Size:</strong> {fileMetadata.size}
            </p>
            <p>
              <strong>Last Modified:</strong> {fileMetadata.lastModified}
            </p>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={`App ${darkMode ? "dark-mode" : "light-mode"}`}>
<header className="header">
  <h1>IPFS Upload</h1>
  <button onClick={() => setDarkMode(!darkMode)} className={`mode-toggle ${darkMode ? 'dark' : 'light'}`}>
    {darkMode ? " Use Light Mode" : " Use Dark Mode"}
  </button>
</header>
      <main className="container">
        <p className="instructions">Select a file to upload to IPFS.</p>

        {/* File upload input */}
        <label className="file-upload">
          <input type="file" onChange={handleFileChange} />
          <span>Choose File</span>
        </label>

        {/* Display the uploaded file URL */}
        {fileUrl && (
          <div className="result">
            <p>File URL:</p>
            <a href={fileUrl} target="_blank" rel="noopener noreferrer">
              {fileUrl}
            </a>
          </div>
        )}

        {/* Render the uploaded file */}
        {renderFilePreview(fileUrl)}

        <div className="list">
          <div className="list-item-darkgray">
            <strong>Reminder about IPFS content:</strong> IPFS uses a public,
            distributed network where files are hashed and visible to everyone.
            Be cautious when uploading sensitive data.
          </div>
          <div className="list">
            <div className="list-item-red">
              <strong> Public Visibility:</strong> Anything uploaded is publicly
              accessible by anyone with the hash. Encrypt sensitive files before
              uploading for added security.
            </div>
          </div>
        </div>

        <div className="list">
          <div className="list-item-darkgray">
          With these precautions in mind, feel free to explore the
          <a
            href="https://image.encrypt.freeweb3.com/"
            className="link"
            target="_blank"
            data-tooltip="IPFS is a public, distributed system. Encrypt sensitive files and avoid uploading private content."
          >
            {" "}
            image.encrypt.freeweb3.com
          </a>{" "}
          site and experience the power of encrypted files to IPFS firsthand.
        </div>
        </div>

      </main>

      <footer className="footer">
        <p>
          <a href="https://ipfs.tech" target="_blank" rel="noopener noreferrer">
            Powered by IPFS
          </a>{" "}
          and{" "}
          <a href="https://infura.io" target="_blank" rel="noopener noreferrer">
            Infura
          </a>
        </p>
        <p>
          © 2025 <strong>Web3 Legal Engineering</strong>. All rights reserved.
        </p>
        <p>
          <a href="https://terms.freeweb3.com" className="footer-link">
            📜 W3LE Terms
          </a>{" "}
          |{" "}
          <a href="https://terms.freeweb3.com" className="footer-link">
            🔒 W3LE Privacy
          </a>
        </p>
      </footer>
    </div>
  );
}

export default App;
